import { useRouter } from "next/router";
import { Box } from "@ui/components/layout/box/Box";
import { Flex } from "@ui/components/layout/flex/Flex";
import { Button } from "@ui/components/forms/button/Button";
import { Text } from "@ui/components/content/text/Text";

type PreviewModeProps = {
    preview: boolean;
};

const PreviewMode = ({ preview }: PreviewModeProps) => {
    const router = useRouter();

    const handleSubmit = () => {
        router.push(`/api/preview/clear?redirect=${encodeURI(router.asPath)}`);
    };

    if (!preview) return null;

    return (
        <Box
            position={"fixed"}
            zIndex={9999}
            color={"white"}
            backgroundColor={"rgba(167, 8, 40, 0.75)"}
            maxWidth={["100%", null, "300px"]}
            width={["100%", null, "300px"]}
            p={1}
            px={10}
            right={["0", null, "200px"]}
            bottom={0}
        >
            <Flex
                justifyContent={"space-around"}
                direction={"row"}
                alignItems={"center"}
                gap={"10px"}
            >
                <Text fontWeight={"bold"} fontSize={"desktopBodyTextSmall"}>
                    PREVIEW
                </Text>
                <Button onClick={handleSubmit} backgroundColor={"rgba(167, 8, 40, 0.9)"} px={10}>
                    Exit
                </Button>
            </Flex>
        </Box>
    );
};

export default PreviewMode;
